import React, { useRef, useCallback, useEffect, useReducer, useState } from 'react';
import jsQR from 'jsqr';

const qrDataReducer = (state, action) => {
    switch (action.type) {
        case 'SET_QR_DATA':
            return { ...state, mensagem: action.payload.mensagem };
        case 'RESET_QR_DATA':
            return null;
        default:
            return state;
    }
};

function Home() {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const lastQRCodeRef = useRef(null);
    const scanningRef = useRef(false);

    const [eventoActual, setEventoActual] = useState({});
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [qrData, dispatch] = useReducer(qrDataReducer, null);
    const [hasPermission, setHasPermission] = useState(false);
    const [permissionError, setPermissionError] = useState(null);
    const [isVideoReady, setIsVideoReady] = useState(false);

    const startCamera = async () => {
        try {
            const constraints = {
                video: {
                    deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
                    facingMode: selectedDeviceId ? undefined : { ideal: 'environment' },
                    width: { ideal: 1280 },
                    height: { ideal: 720 }
                }
            };

            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                await videoRef.current.play();
                setIsVideoReady(true);
                scanningRef.current = true;
                requestAnimationFrame(scan);
            }
        } catch (err) {
            console.error("Error accessing camera:", err);
            setPermissionError(err.message);
        }
    };

    useEffect(() => {
        const meta = document.createElement('meta');
        meta.name = "viewport";
        meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
        document.head.appendChild(meta);
        return () => document.head.removeChild(meta);
    }, []);

    useEffect(() => {
        async function setupCamera() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: { ideal: 'environment' } }
                });
                stream.getTracks().forEach(track => track.stop());
                setHasPermission(true);

                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                const backCamera = videoDevices.find(device =>
                    device.label.toLowerCase().includes('back') ||
                    device.label.toLowerCase().includes('rear') ||
                    device.label.toLowerCase().includes('environment')
                );

                let desiredDeviceId = localStorage.getItem('desiredDeviceId');
                if (!desiredDeviceId || !videoDevices.find(d => d.deviceId === desiredDeviceId)) {
                    desiredDeviceId = backCamera ? backCamera.deviceId : videoDevices[0]?.deviceId;
                }

                localStorage.setItem('desiredDeviceId', desiredDeviceId);
                setSelectedDeviceId(desiredDeviceId);
            } catch (error) {
                console.error('Error setting up camera:', error);
                setPermissionError(error.message);
                setHasPermission(false);
            }
        }

        setupCamera();
        fetch('/eventos/get?idEvento=' + localStorage.getItem('eventoId'))
            .then(response => response.json())
            .then(data => setEventoActual(data))
            .catch(error => console.error('Error fetching eventos:', error));

        return () => {
            if (videoRef.current?.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
            scanningRef.current = false;
            setIsVideoReady(false);
        };
    }, []);

    useEffect(() => {
        if (!hasPermission || !selectedDeviceId) return;
        startCamera();
        return () => {
            if (videoRef.current?.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
            scanningRef.current = false;
            setIsVideoReady(false);
        };
    }, [selectedDeviceId, hasPermission]);

    const scan = async () => {
        if (videoRef.current && videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code && code.binaryData) {
                var binaryData = code.binaryData;
                const decodedString = String.fromCharCode(...binaryData);
                if (lastQRCodeRef.current !== decodedString) {

                    if ("vibrate" in navigator) {
                        navigator.vibrate(50);
                    }

                    const parsedData = await parseQRCode(decodedString);
                    setQrData(parsedData);
                    lastQRCodeRef.current = decodedString;
                }
            } else {
                //console.log("No QR code found");
            }
        }

        // Keep scanning
        requestAnimationFrame(scan);
    };

    const parseQRCode = useCallback(async (qrText) => {
        console.log('Is pasing QR Code.');
        const cleanText = qrText.replace(/\n\n/g, '\n');
        const [idLastConvidado, nomeDoConvidado] = cleanText.split('\n');

        if (!idLastConvidado.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) {
            dispatch({
                type: 'SET_QR_DATA',
                payload: { mensagem: { text: "Ocorreu um erro ao ler o QR Code.", type: "error" } }
            });
            return;
        }

        try {
            const response = await fetch(`/convidadosnoseventos?idConvidado=${idLastConvidado}&idEvento=${localStorage.getItem('eventoId')}`);
            if (!response.ok) throw new Error('Network response was not ok');

            const { estaNoEvento, podeEntrar, numeroDeEntradas } = await response.json();
            let mensagem;

            if (estaNoEvento) {
                if (podeEntrar) {
                    mensagem = {
                        text: `QR Code válido, número de entradas restantes: ${numeroDeEntradas}.`,
                        type: "success"
                    };
                } else {
                    mensagem = {
                        text: "Este QR Code já esgotou as entradas neste evento.",
                        type: "error"
                    };
                }
            } else {
                mensagem = {
                    text: "Este QR Code não está na lista deste evento.",
                    type: "warning"
                };
            }

            dispatch({ type: 'SET_QR_DATA', payload: { mensagem } });
        } catch (error) {
            dispatch({
                type: 'SET_QR_DATA',
                payload: { mensagem: { text: "Ocorreu um erro ao tentar aceder ao servidor.", type: "error" } }
            });
        }
    }, []);

    const handleReset = async () => {
        lastQRCodeRef.current = null;
        dispatch({ type: 'RESET_QR_DATA' });
        setIsVideoReady(false);
        await startCamera();
    };

    return (
        <div className="scanner-container">
            <h2 className="event-title">Evento actual: {eventoActual.designaçãoDoEvento}</h2>

            {permissionError && (
                <div className="error-message">
                    Erro de acesso à câmara: {permissionError}
                </div>
            )}

            {qrData ? (
                <div className="message-container">
                    <p className={`message ${qrData.mensagem.type}`}>
                        {qrData.mensagem.text}
                    </p>
                    <button onClick={handleReset}>Continuar</button>
                </div>
            ) : (
                hasPermission && (
                    <div className="video-container">
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            muted
                            onCanPlay={() => setIsVideoReady(true)}
                        />
                        <div className="scanning-message">
                            Aponte a câmara para o QR Code e aguarde
                        </div>
                    </div>
                )
            )}

            <canvas ref={canvasRef} className="hidden-canvas" />
        </div>
    );
}

export default Home;