import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const TimeIntervalPanel = ({ data, containerStyle, headerStyle, titleStyle }) => (
    <div style={containerStyle}>
        <div style={headerStyle}>
            <h2 style={titleStyle}>Entradas por Intervalo de 15 Minutos</h2>
        </div>
        <div style={{ height: '320px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" name="Entradas" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    </div>
);

export default TimeIntervalPanel;