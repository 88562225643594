import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const HourlyDistributionPanel = ({ data, containerStyle, headerStyle, titleStyle }) => {
    // Filter out hours with no entries
    const filteredData = data.filter(item => item.count > 0);

    // Sort by hour to maintain chronological order
    const sortedData = [...filteredData].sort((a, b) => a.hour - b.hour);

    // Recalculate running average for the filtered data
    const windowSize = 3;
    const dataWithAverage = sortedData.map((item, index) => {
        const start = Math.max(0, index - Math.floor(windowSize / 2));
        const end = Math.min(sortedData.length, index + Math.floor(windowSize / 2) + 1);
        const values = sortedData.slice(start, end).map(h => h.count);
        const avg = values.reduce((sum, val) => sum + val, 0) / values.length;

        return {
            ...item,
            runningAverage: Math.round(avg * 100) / 100
        };
    });

    // Format hour for display
    const formatHour = (hour) => {
        return `${hour.toString().padStart(2, '0')}:00`;
    };

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                <h2 style={titleStyle}>Distribuição Horária com Running Average</h2>
            </div>
            <div style={{ height: '320px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart data={dataWithAverage}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="hour"
                            tickFormatter={formatHour}
                            domain={['dataMin', 'dataMax']}
                        />
                        <YAxis />
                        <Tooltip
                            formatter={(value, name) => [
                                value,
                                name === 'count' ? 'Entradas' : 'Média Móvel'
                            ]}
                            labelFormatter={formatHour}
                        />
                        <Legend
                            payload={[
                                { value: 'Entradas', type: 'rect', color: '#8884d8' },
                                { value: 'Média Móvel', type: 'line', color: '#ff7300' }
                            ]}
                        />
                        <Bar dataKey="count" fill="#8884d8" name="Entradas" />
                        <Line
                            type="monotone"
                            dataKey="runningAverage"
                            stroke="#ff7300"
                            name="Média Móvel"
                            dot={{ fill: '#ff7300' }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default HourlyDistributionPanel;