import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const InviterPerformancePanel = ({ data, inviters, colors, containerStyle, headerStyle, titleStyle }) => (
    <div style={containerStyle}>
        <div style={headerStyle}>
            <h2 style={titleStyle}>Desempenho por Expositor por Hora</h2>
        </div>
        <div style={{ height: '320px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="hour" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {inviters.map((inviter, index) => (
                        <Bar
                            key={inviter.name}
                            dataKey={inviter.name}
                            stackId="a"
                            fill={colors[index % colors.length]}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    </div>
);

export default InviterPerformancePanel;