import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const InviterParetoPanel = ({ data, containerStyle, headerStyle, titleStyle }) => (
    <div style={containerStyle}>
        <div style={headerStyle}>
            <h2 style={titleStyle}>Desempenho dos Expositores (Gráfico de Pareto)</h2>
        </div>
        <div style={{ height: '320px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="left" dataKey="value" fill="#8884d8" name="Entradas" />
                    <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="value"
                        stroke="#ff7300"
                        dot={false}
                        name="Linha de Tendência"
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </div>
);

export default InviterParetoPanel;