// components/sumario/index.js
import React, { useState, useEffect, useMemo } from 'react';
import HeatMapPanel from './HeatMapPanel';
import CumulativeEntriesPanel from './CumulativeEntriesPanel';
import TrendAnalysisPanel from './TrendAnalysisPanel';
import EntryRatePanel from './EntryRatePanel';
import InviterPerformancePanel from './InviterPerformancePanel';
import HourlyDistributionPanel from './HourlyDistributionPanel';
import InviterParetoPanel from './InviterParetoPanel';
import TimeIntervalPanel from './TimeIntervalPanel';
import InviterDistributionPanel from './InviterDistributionPanel';
import TrendAnalysis3D from './TrendAnalysis3D';

const Sumario = () => {
    const [rawData, setRawData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/convidadosnoseventos/sumariogeral');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setRawData(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

    // Process data for 15-minute intervals
    const timeSlotData = useMemo(() => {
        const slots = {};
        rawData.forEach(entry => {
            const date = new Date(entry.dataEHora);
            const hour = date.getHours();
            const minutes = Math.floor(date.getMinutes() / 15) * 15;
            const timeKey = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            slots[timeKey] = (slots[timeKey] || 0) + 1;
        });
        return Object.entries(slots)
            .map(([time, count]) => ({ time, count }))
            .sort((a, b) => a.time.localeCompare(b.time));
    }, [rawData]);

    // Process data for inviter distribution
    const inviterData = useMemo(() => {
        const counts = {};
        rawData.forEach(entry => {
            counts[entry.expositor] = (counts[entry.expositor] || 0) + 1;
        });
        return Object.entries(counts)
            .map(([name, value]) => ({ name, value }))
            .sort((a, b) => b.value - a.value);
    }, [rawData]);

    // Process data for hourly entries with running average
    const hourlyData = useMemo(() => {
        const hours = {};
        rawData.forEach(entry => {
            const date = new Date(entry.dataEHora);
            const hour = date.getHours();
            hours[hour] = (hours[hour] || 0) + 1;
        });

        const hourlyArray = Array.from({ length: 24 }, (_, i) => ({
            hour: i,
            count: hours[i] || 0
        }));

        const windowSize = 3;
        return hourlyArray.map((item, index) => {
            const start = Math.max(0, index - Math.floor(windowSize / 2));
            const end = Math.min(hourlyArray.length, index + Math.floor(windowSize / 2) + 1);
            const values = hourlyArray.slice(start, end).map(h => h.count);
            const avg = values.reduce((sum, val) => sum + val, 0) / values.length;
            return {
                ...item,
                runningAverage: Math.round(avg * 100) / 100
            };
        });
    }, [rawData]);

    // Process data for inviter performance over time
    const inviterTimeData = useMemo(() => {
        const timeData = {};
        rawData.forEach(entry => {
            const date = new Date(entry.dataEHora);
            const hour = date.getHours();
            const expositor = entry.expositor;

            if (!timeData[hour]) {
                timeData[hour] = {};
            }
            timeData[hour][expositor] = (timeData[hour][expositor] || 0) + 1;
        });

        return Object.entries(timeData).map(([hour, data]) => ({
            hour: parseInt(hour),
            ...data
        }));
    }, [rawData]);

    // Process data for entry rate change
    const entryRateData = useMemo(() => {
        const intervals = {};
        rawData.forEach(entry => {
            const date = new Date(entry.dataEHora);
            const hour = date.getHours();
            const interval = Math.floor(date.getMinutes() / 30);
            const key = `${hour}:${interval * 30}`;
            intervals[key] = (intervals[key] || 0) + 1;
        });

        const sortedIntervals = Object.entries(intervals)
            .sort(([a], [b]) => a.localeCompare(b))
            .map(([time, count], index, array) => ({
                time,
                count,
                changeRate: index > 0 ? ((count - array[index - 1][1]) / array[index - 1][1] * 100) : 0
            }));

        return sortedIntervals;
    }, [rawData]);

    // Dados cumulativos ao longo do dia
    const cumulativeData = useMemo(() => {
        const sortedData = [...rawData].sort((a, b) =>
            new Date(a.dataEHora).getTime() - new Date(b.dataEHora).getTime()
        );

        let cumulative = 0;
        const cumulativePoints = sortedData.map(entry => {
            cumulative += 1;
            const date = new Date(entry.dataEHora);
            return {
                hora: `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`,
                total: cumulative
            };
        });

        // Optional: you can reduce the number of points to improve performance
        // by grouping by minute or 5-minute intervals if needed
        return cumulativePoints;
    }, [rawData]);

    // Dados para scatter plot com linha de tendência
    const scatterData = useMemo(() => {
        return rawData.map((entry, index) => {
            const date = new Date(entry.dataEHora);
            return {
                x: date.getHours() + (date.getMinutes() / 60),
                y: index + 1,
                expositor: entry.expositor
            };
        });
    }, [rawData]);

    // Heat map data
    const heatMapData = useMemo(() => {
        const heatData = [];
        rawData.forEach(entry => {
            const hour = new Date(entry.dataEHora).getHours();
            const existingHour = heatData.find(d => d.hora === hour);
            if (existingHour) {
                existingHour.quantidade += 1;
            } else {
                heatData.push({ hora: hour, quantidade: 1 });
            }
        });

        return heatData.sort((a, b) => a.hora - b.hora);
    }, [rawData]);

    // Calculate trendline
    const calculateTrendline = (data) => {
        const n = data.length;
        let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;

        // Only use data points that exist
        const validData = data.filter(point => point.x != null && point.y != null);

        validData.forEach(point => {
            sumX += point.x;
            sumY += point.y;
            sumXY += point.x * point.y;
            sumX2 += point.x * point.x;
        });

        const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
        const intercept = (sumY - slope * sumX) / n;

        const minTime = Math.min(...validData.map(d => d.x));
        const maxTime = Math.max(...validData.map(d => d.x));

        return {
            start: { x: minTime, y: slope * minTime + intercept },
            end: { x: maxTime, y: slope * maxTime + intercept }
        };
    };

    const trendline = calculateTrendline(scatterData);
    const trendlineData = [trendline.start, trendline.end];

    if (loading) {
        return <div className="p-4">A carregar dados...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-500">Erro ao carregar dados: {error}</div>;
    }

    const chartContainerStyle = {
        backgroundColor: 'white',
        border: '1px solid #e5e7eb',
        borderRadius: '0.5rem',
        padding: '1rem',
        marginBottom: '1rem'
    };

    const chartHeaderStyle = {
        marginBottom: '1rem'
    };

    const chartTitleStyle = {
        fontSize: '1.25rem',
        fontWeight: 'bold'
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Painel de Análise de Entradas</h1>

            <HeatMapPanel
                data={heatMapData}
                containerStyle={chartContainerStyle}
                headerStyle={chartHeaderStyle}
                titleStyle={chartTitleStyle}
            />

            {/*<CumulativeEntriesPanel*/}
            {/*    data={cumulativeData}*/}
            {/*    containerStyle={chartContainerStyle}*/}
            {/*    headerStyle={chartHeaderStyle}*/}
            {/*    titleStyle={chartTitleStyle}*/}
            {/*/>*/}

            {/*<TrendAnalysisPanel*/}
            {/*    data={scatterData}*/}
            {/*    trendlineData={trendlineData}*/}
            {/*    containerStyle={chartContainerStyle}*/}
            {/*    headerStyle={chartHeaderStyle}*/}
            {/*    titleStyle={chartTitleStyle}*/}
            {/*/>*/}

            {/*<TrendAnalysis3D*/}
            {/*    data={scatterData}*/}
            {/*    containerStyle={chartContainerStyle}*/}
            {/*    headerStyle={chartHeaderStyle}*/}
            {/*    titleStyle={chartTitleStyle}*/}
            {/*/>*/}

            <EntryRatePanel
                data={entryRateData}
                containerStyle={chartContainerStyle}
                headerStyle={chartHeaderStyle}
                titleStyle={chartTitleStyle}
            />

            <InviterPerformancePanel
                data={inviterTimeData}
                inviters={inviterData}
                colors={COLORS}
                containerStyle={chartContainerStyle}
                headerStyle={chartHeaderStyle}
                titleStyle={chartTitleStyle}
            />

            <HourlyDistributionPanel
                data={hourlyData}
                containerStyle={chartContainerStyle}
                headerStyle={chartHeaderStyle}
                titleStyle={chartTitleStyle}
            />

            <InviterParetoPanel
                data={inviterData}
                containerStyle={chartContainerStyle}
                headerStyle={chartHeaderStyle}
                titleStyle={chartTitleStyle}
            />

            <TimeIntervalPanel
                data={timeSlotData}
                containerStyle={chartContainerStyle}
                headerStyle={chartHeaderStyle}
                titleStyle={chartTitleStyle}
            />

            <InviterDistributionPanel
                data={inviterData}
                colors={COLORS}
                containerStyle={chartContainerStyle}
                headerStyle={chartHeaderStyle}
                titleStyle={chartTitleStyle}
            />
        </div>
    );
};

export default Sumario;