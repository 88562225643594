import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const HeatMapPanel = ({ data, containerStyle, headerStyle, titleStyle }) => {
    // Filter out entries with quantidade = 0
    const filteredData = data.filter(entry => entry.quantidade > 0);

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                <h2 style={titleStyle}>Heat Map de Padrões de Entrada</h2>
            </div>
            <div style={{ height: '320px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={filteredData} layout="vertical">
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis dataKey="hora" type="category" />
                        <Tooltip />
                        <Bar
                            dataKey="quantidade"
                            fill="#8884d8"
                            background={{ fill: '#eee' }}
                        >
                            {filteredData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={`rgb(37, 99, 235, ${entry.quantidade / Math.max(...filteredData.map(d => d.quantidade))})`}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default HeatMapPanel;