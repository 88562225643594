import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const InviterDistributionPanel = ({ data, colors, containerStyle, headerStyle, titleStyle }) => (
    <div style={containerStyle}>
        <div style={headerStyle}>
            <h2 style={titleStyle}>Distribuição por Expositor</h2>
        </div>
        <div style={{ height: '320px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </div>
    </div>
);

export default InviterDistributionPanel;