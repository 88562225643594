import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const EntryRatePanel = ({ data, containerStyle, headerStyle, titleStyle }) => (
    <div style={containerStyle}>
        <div style={headerStyle}>
            <h2 style={titleStyle}>Variação da Taxa de Entrada (intervalos de 30 min)</h2>
        </div>
        <div style={{ height: '320px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="left" dataKey="count" fill="#8884d8" name="Entradas" />
                    <Line yAxisId="right" type="monotone" dataKey="changeRate" stroke="#82ca9d" name="Variação %" />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </div>
);

export default EntryRatePanel;
